body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  overflow-x: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Gotham-Bold";
  src: local("Gotham-Bold"),
    url("./assets/fonts/Gotham-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Gotham-Black";
  src: local("Gotham-Black"),
    url("./assets/fonts/Gotham-Black.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Gotham-Book";
  src: local("Gotham-Book"),
    url("./assets/fonts/Gotham-Book.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Gotham-Medium";
  src: local("Gotham-Medium"),
    url("./assets/fonts/Gotham-Medium.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("./assets/fonts/Lato-Bold.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular"),
    url("./assets/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: lighter;
}

p {
  font-family: "Lato-Regular";
  font-size: 20px;
  color: #000;
}

strong {
  font-family: "Gotham-Bold";
  font-size: 0.9em;
  color: #336699;
}